export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Report',
        to: '/report',
        icon: 'cil-map',
        badge: {
          color: 'primary',
          text: 'Report'
        },
        _class:'report-menu'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Smart Search',
        to: '/smartsearch',
        icon: 'cil-search',
        badge: {
          color: 'primary',
          text: 'Beta'
        }
      },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Offer']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Create Offer',
      //   to: '/offer/newoffer',
      //   icon: 'cil-applications',
      //   _class:'create-offer'
      // },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Traffic Source']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Create Traffic Source',
      //   to: '/ts/newts',
      //   icon: 'cil-applications',
      //   _class:'traffic-source'
      // },
      
      {
        _name: 'CSidebarNavTitle',
        _children: ['Manage']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Create Campaign',
        to: '/campaign/newcampaign',
        icon: 'cil-bullhorn',
        _class:'create-campaign'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Campaign List',
        to: '/campaign/campaignlist',
        icon: 'cil-spreadsheet',
        _class:'campaign-list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Offer List',
        to: '/offer/offerlist',
        icon: 'cil-spreadsheet'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Traffic Source List',
        to: '/ts/tslist',
        icon: 'cil-spreadsheet'
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Video List',
      //   to: '/campaign/videolist',
      //   icon: 'cil-video'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Heat Map',
      //   to: '/campaign/hitmap',
      //   icon: 'cil-image'
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Generate SDK',
        to: '/campaign/generatesdk',
        icon: 'cil-code',
        _class:'sdk-menu'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Setting']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Settings',
        route: '/setting/accountsetting',
        icon: 'cil-settings',
        items: [
          {
            name: 'Custom Domains',
            to: '/setting/customdomain'
          },
          {
            name: 'Account Setting',
            to: '/setting/accountsetting'
          },
          {
            name: 'Plan Info',
            to: '/setting/planinfo'
          },
          {
            name: 'Master Security',
            to: '/setting/mastersetting'
          },
          {
            name: 'Javascript Setting',
            to: '/setting/jssetting'
          },
          {
            name: 'APK Security',
            to: '/setting/apksetting'
          },
          {
            name: 'Domain Security',
            to: '/setting/domain'
          },
          {
            name: 'IP Whitelist',
            to: '/setting/ip'
          },
          {
            name: 'Postback Security',
            to: '/setting/postbacksecurity'
          },
          {
            name: 'Developers',
            to: '/setting/developer'
          },
          {
            name: 'Postback Logs',
            to: '/setting/postbacklogs'
          },
          
        ]},
        {
          _name: 'CSidebarNavDropdown',
          name: 'Documentation',
          route: '.documentation/sdkdocument',
          icon: 'cil-file',
          items: [
            {
              name: 'SDK Documentation',
              to: '/document/sdkdocument'
            },
            {
              name: 'Create Offer',
              to: '/document/createoffer'
            },
            {
              name: 'Edit Offer',
              to: '/document/editOffer'
            },
            {
              name: 'Get Offer Info',
              to: '/document/getofferinfo'
            },
            {
              name: 'Offer Delete',
              to: '/document/offerdelete'
            },
            {
              name: 'List Offer',
              to: '/document/offerlist'
            },
            {
              name: 'Create Traffic Source',
              to: '/document/createtrafficsource'
            },
            {
              name: 'Edit Traffic Source',
              to: '/document/edittraficsource'
            },
            {
              name: 'Delete Traffic Source',
              to: '/document/deletetrafficsource'
            },
            {
              name: 'List Traffic Source',
              to: '/document/listtrafficsource'
            },
            {
              name: 'Traffic Source Info',
              to: '/document/trafficsourceinfo'
            },
            {
              name: 'Create Campaign',
              to: '/document/createcampaign'
            },
             {
              name: 'Edit Campaign',
              to: '/document/editcampaign'
            },
            {
              name: 'Get Campaign Info',
              to: '/document/getcampaigninfo'
            },
            {
              name: 'List Campaign',
              to: '/document/campaignlist'
            },
            {
              name: 'Delete Campaign',
              to: '/document/campaignremove'
            },
            {
              name: 'Campaign List API',
              to: '/document/campaignlist'
            },
            {
              name : 'Report Summary',
              to: '/document/reportsummary'
            },
            {
              name : 'Report',
              to: '/document/reportdoc'
            },
            {
              name: 'View Logs Info',
              to: '/document/clickfinderinfo'
            },
            {
              name: 'Custom Postback',
              to: '/document/customizepostback'
            }
          ]
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Logout',
          to: '/pages/logout',
          icon: { name: 'cil-power-standby', class: 'text-white' },
          _class: 'bg-danger text-white',
        },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Theme']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Colors',
      //   to: '/theme/colors',
      //   icon: 'cil-drop'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Typography',
      //   to: '/theme/typography',
      //   icon: 'cil-pencil'
      // },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Components']
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Base',
      //   route: '/base',
      //   icon: 'cil-puzzle',
      //   items: [
      //     {
      //       name: 'Breadcrumbs',
      //       to: '/base/breadcrumbs'
      //     },
      //     {
      //       name: 'Cards',
      //       to: '/base/cards'
      //     },
      //     {
      //       name: 'Carousels',
      //       to: '/base/carousels'
      //     }, 
      //     {
      //       name: 'Collapses',
      //       to: '/base/collapses'
      //     },
      //     {
      //       name: 'Forms',
      //       to: '/base/forms'
      //     },
      //     {
      //       name: 'Jumbotrons',
      //       to: '/base/jumbotrons'
      //     },
      //     {
      //       name: 'List Groups',
      //       to: '/base/list-groups'
      //     },
      //     {
      //       name: 'Navs',
      //       to: '/base/navs'
      //     },
      //     {
      //       name: 'Navbars',
      //       to: '/base/navbars'
      //     },
      //     {
      //       name: 'Paginations',
      //       to: '/base/paginations'
      //     },
      //     {
      //       name: 'Popovers',
      //       to: '/base/popovers'
      //     },
      //     {
      //       name: 'Progress Bars',
      //       to: '/base/progress-bars'
      //     },
      //     {
      //       name: 'Switches',
      //       to: '/base/switches'
      //     },
      //     {
      //       name: 'Tables',
      //       to: '/base/tables'
      //     },
      //     {
      //       name: 'Tabs',
      //       to: '/base/tabs'
      //     },
      //     {
      //       name: 'Tooltips',
      //       to: '/base/tooltips'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Buttons',
      //   route: '/buttons',
      //   icon: 'cil-cursor',
      //   items: [
      //     {
      //       name: 'Buttons',
      //       to: '/buttons/standard-buttons'
      //     },
      //     {
      //       name: 'Button Dropdowns',
      //       to: '/buttons/dropdowns'
      //     },
      //     {
      //       name: 'Button Groups',
      //       to: '/buttons/button-groups'
      //     },
      //     {
      //       name: 'Brand Buttons',
      //       to: '/buttons/brand-buttons'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Charts',
      //   to: '/charts',
      //   icon: 'cil-chart-pie'
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Icons',
      //   route: '/icons',
      //   icon: 'cil-star',
      //   items: [
      //     {
      //       name: 'CoreUI Icons',
      //       to: '/icons/coreui-icons',
      //       badge: {
      //         color: 'info',
      //         text: 'NEW'
      //       }
      //     },
      //     {
      //       name: 'Brands',
      //       to: '/icons/brands'
      //     },
      //     {
      //       name: 'Flags',
      //       to: '/icons/flags'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Notifications',
      //   route: '/notifications',
      //   icon: 'cil-bell',
      //   items: [
      //     {
      //       name: 'Alerts',
      //       to: '/notifications/alerts'
      //     },
      //     {
      //       name: 'Badges',
      //       to: '/notifications/badges'
      //     },
      //     {
      //       name: 'Modals',
      //       to: '/notifications/modals'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Widgets',
      //   to: '/widgets',
      //   icon: 'cil-calculator',
      //   badge: {
      //     color: 'primary',
      //     text: 'NEW',
      //     shape: 'pill'
      //   }
      // },
      // {
      //   _name: 'CSidebarNavDivider',
      //   _class: 'm-2'
      // },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Extras']
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'Pages',
      //   route: '/pages',
      //   icon: 'cil-star',
      //   items: [
      //     {
      //       name: 'Login',
      //       to: '/pages/login'
      //     },
      //     {
      //       name: 'Register',
      //       to: '/pages/register'
      //     },
      //     {
      //       name: 'Error 404',
      //       to: '/pages/404'
      //     },
      //     {
      //       name: 'Error 500',
      //       to: '/pages/500'
      //     }
      //   ]
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Download CoreUI',
      //   href: 'http://coreui.io/vue/',
      //   icon: { name: 'cil-cloud-download', class: 'text-white' },
      //   _class: 'bg-success text-white',
      //   target: '_blank'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Try CoreUI PRO',
      //   href: 'http://coreui.io/pro/vue/',
      //   icon: { name: 'cil-layers', class: 'text-white' },
      //   _class: 'bg-danger text-white',
      //   target: '_blank'
      // }
    ]
  }
]