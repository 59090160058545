export default [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'User List',
          to: '/moderator/userlist',
          icon: 'cil-user',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Checks Report',
          to: '/moderator/checksreprot',
          icon: 'cil-speedometer',
          // badge: {
          //   color: 'primary',
          //   text: 'NEW'
          // }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'User Events',
          to: '/moderator/userevents',
          icon: 'cil-user',
          badge: {
            color: 'primary',
            text: 'NEW'
          }
        },
      ]
    }
  ]