import api from './api'

export default {
  login(data){
    return api.execute(`post`,`/login`, data)
  },
  forgetpwd(data){
    return api.execute(`post`,`/forgetpwd`, data)
  },
  changepwd(data){
    return api.execute(`post`,`/changepwd`, data)
  },
  authenticate(){
    return api.execute(`get`,`/alive`)
  },
  logout(){
    return api.execute(`get`,`/logout`)
  },
  getOtp(){
    return api.execute(`get`,`/genotp`)
  },
  verifyOtp(data){
    return api.execute(`post`,`/verify`,data)
  },
  onboard(data){
    return api.execute(`post`,`/onboard`,data)
}
}
