<template>
    <div>
    <v-tour :name="name" :steps="steps">
      <template slot-scope="tour">
    <transition name="fade">
      <v-step
        v-if="tour.steps[tour.currentStep]"
        :key="tour.currentStep"
        :step="tour.steps[tour.currentStep]"
        :previous-step="tour.previousStep"
        :next-step="tour.nextStep"
        :stop="tour.stop"
        :skip="tour.skip"
        :is-first="tour.isFirst"
        :is-last="tour.isLast"
        :labels="tour.labels"
        :id="tour.steps[tour.currentStep].id"
      >
        <template>
          <div slot="actions" class="text-center">
            <button v-if="tour.isFirst != true && tour.isLast != true" class="btn btn-light font-weight-bold text-black" @click="Previous(tour)">Previous Step</button>
            <!-- v-if="tour.steps[tour.currentStep].islast_tour" -->
            <button v-if="tour.steps[tour.currentStep].islast_tour" class="ml-2 btn btn-light font-weight-bold text-black" @click="Finish(tour)">Finish tour</button>
            <button v-else class="btn btn-light font-weight-bold text-black ml-2" @click="Finish(tour)">Exit</button>
            <button id="next-btn" v-if="!tour.steps[tour.currentStep].islast_tour" class="btn btn-light ml-2 font-weight-bold text-black" @click="Next(tour,tour.steps[tour.currentStep])">Next Step</button>
          </div>
        </template>
        <!-- <template v-if="name =='offer'" >
          <div slot="actions"> -->
            <!-- <button @click="tour.previousStep" class="btn btn-primary">Previous step</button> -->
            
          <!-- </div>
        </template> -->
      </v-step>
    </transition>
  </template></v-tour>
  </div>
</template>



<script>
import userguide from '../services/userguide';

export default{
    name:'vueTour',
    props:{name:String},
    data(){
        return{
            steps:[],
            tours:['sidebar','ts','offer','campaign','camplist','sdk','report']
        }
    },
    beforeMount(){
        switch(this.name){
            case 'sidebar':this.steps = userguide.step1
                break;
            case 'campaign': this.steps = userguide.step2
                break;
            case 'camplist': this.steps = userguide.step3
                break;
            case 'sdk': this.steps = userguide.step4
                break;
            case 'report': this.steps = userguide.step5
                break;
            case 'offer':this.steps = userguide.step6
                break;
            case 'ts': this.steps = userguide.step7
                break;
        }
        // console.log(this.name)
        // this.FocusIn(this.steps)
    },
    mounted(){
      if(window.localStorage.getItem('guide_tool') == 'true'){
      if(!this.steps[0].nofocus){
      var element = document.querySelector(this.steps[0].target);
      if(element){
      element.className += " " + 'focus_guide';
      }
      }}
      // if(this.$tours[this.name].currentStep == -1){
      // var element = document.querySelector('.offerinfo');
      // element.className += " " + 'focus_guide';
      // }
    },
    methods:{
        Next(data,click){
          // console.log(click.clickOnnext)
          this.openCloseModals(data.steps[data.currentStep])
            if(data.isLast){
                if(data.steps[data.currentStep].next){
                this.$router.push(data.steps[data.currentStep].next);
                }
                this.$tours[this.name].stop()
            }
            else{
              data.currentStep++;
            }
            // if(click.clickOnnext){
            //   this.ClickOnId(click.clickOnnext)
            // }
            this.FocusIn(data,data.currentStep)
            
            // this.$emit('enableHelp')
            // this.$tours['tour'].stop()
            this.$tours[this.name].nextStep()
            // console.log(this.steps)
            
            // console.log(data.steps[data.currentStep].next)
            // if(data.steps[data.currentStep].next){
            // this.$router.push(data.steps[data.currentStep].next);
            // }
        },
        Previous(data){
          if(data.isLast){
                if(data.steps[data.currentStep].next){
                this.$router.push(data.steps[data.currentStep].next);
                }
                this.$tours[this.name].stop()
            }
            else{
              data.currentStep--;
            }
            this.FocusOut(data,data.currentStep)
            this.FocusIn(data,data.currentStep)
            this.$tours[this.name].previousStep()
        },
        Finish(){
            window.localStorage.setItem('guide_tool',false)
            console.log(this.$tours)
            
            this.tours.map((t)=> {
              if(this.$tours[t]){
              this.$tours[t].stop()
              }
            })
            var el = document.querySelectorAll('.focus_guide')
            for (let i = 0; i < el.length; i++) {
              el[i].classList.remove('focus_guide');
            }
            // var element = document.querySelector('.offerinfo');
            // element.classList.remove('focus_guide');
            // this.$router.push('/report')
            // location.reload();
        },
        FocusIn(data,currentstep){
          let d = this.steps[currentstep]
          if(d.removefocus){
          var element = document.querySelector(d.removefocus);
          if(element){
          element.classList.remove('focus_guide');
          }
        }
          if(!d.nofocus){
          var e = document.querySelector(d.target);
          if(e){
          e.className += " " + 'focus_guide';
          }
        }
        if(d.removePreFocus == "#campurlforselect"){
            let el = document.querySelector('.close_camp_url')
            if(el){
              el.click();
            }
          }
          // if(data.isLast){

          // }
          // element.className += " " + 'focus_guide';
          // let currentStep = steps[0]
          // if(data){
          //   currentStep = steps[data.currentStep]
          // }
          // console.log(currentStep)

            // if(this.$tours['offer'].currentStep == -1){
            //   var element = document.querySelector('.offerinfo');
            //   element.className += " " + 'focus_guide';
            // }
        },
        FocusOut(data,currentstep){
          let d = this.steps[currentstep]
          if(d.removePreFocus){
          var element = document.querySelector(d.removePreFocus);
          element.classList.remove('focus_guide');
        }
        },
        openCloseModals(data){
          if(data.openModal){
            let e = document.querySelector(data.target)
            if(e){ e.click() }
          }else if(data.closeModal){
            let e = document.querySelector('#closeModal')
            e.click()
          }
        }
    }
}
</script>


<style scoped>
.v-step{
  z-index: 1049 !important;
}
</style>