<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import LoginService from './../services/loginControlService'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  data() {
    return {
      showLogoutModal: false,
      logoutTimeout: null,
      inactivityDuration: 1000 * 60 * 60, // 5 minutes (in milliseconds)
    };
  },
   mounted() {
    // Set up initial timeout
    this.setupLogoutTimer();

    // Add event listeners for user activity
    document.addEventListener("mousemove", this.handleUserActivity);
    document.addEventListener("keydown", this.handleUserActivity);
    document.addEventListener("touchstart", this.handleUserActivity);
  },
  methods: {
    setupLogoutTimer() {
      // Clear existing timeout, if any
      clearTimeout(this.logoutTimeout);
      if(window.localStorage.getItem('logoutTimeout')){
        this.inactivityDuration = window.localStorage.getItem('logoutTimeout')
      }
      //console.log("Setting up logout timer...", this.inactivityDuration);
      // Set up a new timeout for logout
      this.logoutTimeout = setTimeout(() => {
        this.logout()
      }, this.inactivityDuration);
    },
    handleUserActivity() {
      // Reset the logout timer on user activity
      this.setupLogoutTimer();
    },
    async logout() {
      // Perform logout logic here, e.g., clear session, redirect, etc.
      // After logout, you might want to reset the component or redirect to the login page.
      
      this.showLogoutModal = false;
      clearTimeout(this.logoutTimeout);
       let response = await LoginService.logout()
              window.localStorage.setItem("user", null);
              window.localStorage.setItem("status", null);
              window.localStorage.setItem("email", null);
              window.localStorage.setItem("iwitness", 'false');
              this.$router.push("/pages/login");
      // Your logout logic goes here
    },
  },
  beforeDestroy() {
    // Remove event listeners when the component is destroyed
    document.removeEventListener("mousemove", this.handleUserActivity);
    document.removeEventListener("keydown", this.handleUserActivity);
    document.removeEventListener("touchstart", this.handleUserActivity);
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
