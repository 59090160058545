export default [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/dashboard',
          icon: 'cil-speedometer',
          badge: {
            color: 'primary',
            text: 'NEW'
          }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Report',
          to: '/report',
          icon: 'cil-map',
          badge: {
            color: 'primary',
            text: 'Report'
          },
          _class:'report-menu'
        },
        // {
        //   _name: 'CSidebarNavTitle',
        //   _children: ['Offer']
        // },
        // {
        //   _name: 'CSidebarNavItem',
        //   name: 'Create Offer',
        //   to: '/offer/newoffer',
        //   icon: 'cil-applications',
        //   _class:'create-offer'
        // },
        // {
        //   _name: 'CSidebarNavTitle',
        //   _children: ['Traffic Source']
        // },
        // {
        //   _name: 'CSidebarNavItem',
        //   name: 'Create Traffic Source',
        //   to: '/ts/newts',
        //   icon: 'cil-applications',
        //   _class:'traffic-source'
        // },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Manage']
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Create Campaign',
          to: '/campaign/newcampaign',
          icon: 'cil-bullhorn',
          _class:'create-campaign'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Campaign list',
          to: '/campaign/campaignlist',
          icon: 'cil-spreadsheet',
          _class:'campaign-list'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Offer list',
          to: '/offer/offerlist',
          icon: 'cil-spreadsheet'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Traffic Source list',
          to: '/ts/tslist',
          icon: 'cil-spreadsheet'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Generate SDK',
          to: '/campaign/generatesdk',
          icon: 'cil-code',
          _class:'sdk-menu'
        },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Setting']
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Setting',
          route: '/setting/accountsetting',
          icon: 'cil-settings',
          items: [
            {
              name: 'Custom Domains',
              to: '/setting/customdomain'
            },
            {
              name: 'Account Setting',
              to: '/setting/accountsetting'
            },
            {
              name: 'Plan Info',
              to: '/setting/planinfo'
            },
            {
              name: 'Master Security',
              to: '/setting/mastersetting'
            },
            {
              name: 'Javascript Setting',
              to: '/setting/jssetting'
            },
            {
              name: 'APK Security',
              to: '/setting/apksetting'
            },
            {
              name: 'Domain Security',
              to: '/setting/domain'
            },
            {
              name: 'IP Whitelist',
              to: '/setting/ip'
            },
            {
              name: 'Postback Security',
              to: '/setting/postbacksecurity'
            },
            {
              name: 'Developers',
              to: '/setting/developer'
            },
            {
              name: 'Postback Logs',
              to: '/setting/postbacklogs'
            },
            
          ]},
          {
            _name: 'CSidebarNavDropdown',
            name: 'Documentation',
            route: '.documentation/sdkdocument',
            icon: 'cil-file',
            items: [
              {
                name: 'SDK Documentation',
                to: '/document/sdkdocument'
              },
              {
                name: 'Create Offer',
                to: '/document/createoffer'
              },
              {
                name: 'Get Offer Info',
                to: '/document/getofferinfo'
              },
              {
                name: 'Offer Delete',
                to: '/document/offerdelete'
              },
              {
                name: 'List Offer',
                to: '/document/offerlist'
              },
              {
                name: 'Create Traffic Source',
                to: '/document/createtrafficsource'
              },
              {
                name: 'Edit Traffic Source',
                to: '/document/edittraficsource'
              },
              {
                name: 'Delete Traffic Source',
                to: '/document/deletetrafficsource'
              },
              {
                name: 'List Traffic Source',
                to: '/document/listtrafficsource'
              },
              {
                name: 'Traffic Source Info',
                to: '/document/trafficsourceinfo'
              },
              {
                name: 'Create Campaign',
                to: '/document/createcampaign'
              },
               {
                name: 'Edit Campaign',
                to: '/document/editcampaign'
              },
              {
                name: 'Get Campaign Info',
                to: '/document/getcampaigninfo'
              },
              {
                name: 'List Campaign',
                to: '/document/campaignlist'
              },
              {
                name: 'Delete Campaign',
                to: '/document/campaignremove'
              },
              {
                name: 'Campaign List API',
                to: '/document/campaignlist'
              },
              {
                name : 'Report Summary',
                to: '/document/reportsummary'
              },
              {
                name : 'Report',
                to: '/document/reportdoc'
              },
              {
                name: 'View Logs Info',
                to: '/document/clickfinderinfo'
              },
              {
                name: 'Custom Postback',
                to: '/document/customizepostback'
              }
            ]
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Logout',
            to: '/pages/logout',
            icon: { name: 'cil-power-standby', class: 'text-white' },
            _class: 'bg-danger text-white',
          },
      ]
    }
  ]