export default [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/dashboard',
          icon: 'cil-speedometer',
          badge: {
            color: 'primary',
            text: 'NEW'
          }
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Report',
          to: '/report',
          icon: 'cil-map',
          badge: {
            color: 'primary',
            text: 'Report'
          },
          _class:'report-menu'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Campaign List',
          to: '/campaign/campaignlist',
          icon: 'cil-spreadsheet',
          _class:'campaign-list'
        },
        {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/pages/logout',
        icon: { name: 'cil-power-standby', class: 'text-white' },
        _class: 'bg-danger text-white',
        },
      ]
    }
  ]