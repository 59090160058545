<template>
  <CSidebar 
    v-if="user_status == 'true'"
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
    <CHeaderNavLink>
        <div class="c-sidebar-brand-full"
        name="logo" 
        size="custom-size" 
        >
          <!-- <img
            src="img/logo/Fraudavoid_l_logo.png"
            style="height:40px; width:130px;"
          /> -->
          <img
            src="img/logo/sidelogo.png"
            style="top: 57px;left: 84px;width: 167px;height: 50px;"
          />
        </div>
      </CHeaderNavLink>
      <CHeaderNavLink>
        <div class="c-sidebar-brand-minimized"
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134">
          <img
            src="img/logo/logo.png"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
      <!-- <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      /> -->
    </CSidebarBrand>

    <CRenderFunction v-if="user == 'manager'" flat :content-to-render="$options.managernav"/>
    <CRenderFunction v-if="(user == 'user' || user == 'subUser') && iwitness == 'false'" flat :content-to-render="$options.navnotiwitness"/>
    <CRenderFunction v-if="(user == 'user' || user == 'subUser') && iwitness != 'false'" flat :content-to-render="$options.nav"/>
    <CRenderFunction v-if="user == 'csp'" flat :content-to-render="$options.navCspUser"/>
    <CRenderFunction v-if="user == 'moderator'" flat :content-to-render="$options.nacmoderatorUser"/>
    
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navCspUser from './_cspUserNav'
import nacmoderatorUser from './_moderatorUserNav'
import navnotiwitness from './_navnotiwitness'
import managernav from './manager_nav'

export default {
  name: 'TheSidebar',
  nav,
  managernav,
  navnotiwitness,
  navCspUser,
  nacmoderatorUser,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  data(){
    return{
      user:'',
      user_status:false,
      usermail:'',
      iwitness:'false',
    }
  },
  mounted(){
    this.usermail = window.localStorage.getItem("email");
    this.user = window.localStorage.getItem("user");
    this.user_status = window.localStorage.getItem("status");
    this.iwitness = window.localStorage.getItem("iwitness");
  },
}
</script>
