<template>
  <CFooter :fixed="false">
    <div>
      <a href="/" target="">FraudAvoid</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Scienlabs Technologies.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="http://scienlabs.com/?ref=fraudavoid" target="_blank">Scienlabs Technologies</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
