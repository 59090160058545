module.exports = {
  step1:[
    {
      target: '.create-campaign', 
      header: {
        title: 'Step 1',
      },
      content: `Launch A Successful Campaign. Click Here To Start Creating Your Own Campaign.`,
      params: {
        placement: 'right',
        enableScrolling: true,
      },
      next:'/campaign/newcampaign',
      nofocus:false,
      removefocus:'',
      removePreFocus:'',
      id:'createCamp'
    }
  ],
  step2:[
    {
      target: '.campinfo', 
      header: {
        title: 'Step 2',
      },
      content: `1. Create a Campaign. <br>
      2. Name it. <br>
      3. Select an Offer.<br>
      4. Select the Traffic source you want to use for your campaign.`,
      params: {
        placement: 'right',
        enableScrolling: false
      },
      nofocus:false,
      next:'',
      removePreFocus:'.create-offer',
      removefocus:'.campinfo',
    },
    {
      target: '.create-offer', 
      header: {
        title: 'Step 3',
      },
      content: `Please click here to create your offer!`,
      params: {
        placement: 'right',
        enableScrolling: false
      },
      next:'',
      openModal:true,
      nofocus:false,
      nextfocus:'.traffic-source',
      removefocus:'.campinfo',
      removePreFocus:'.traffic-source',
      id:'createOffer'
    },
    {
      target: '.traffic-source', 
      header: {
        title: 'Step 4',
      },
      content: `Creating Your Own Traffic Source. Click Here To Get Started Now!`,
      params: {
        placement: 'right',
        enableScrolling: false,
      },
      next:'',
      openModal:true,
      nofocus:false,
      removefocus:'.create-offer',
      removePreFocus:'.security-section',
      id:'createTs'
    },
    {
      target: '.security-section', 
      header: {
        title: 'Step 5',
      },
      content: `To begin, click on any of the traffic that you want to block. For more information, hover your mouse over the section. We recommend selecting Automated Software Level 1. It's the best way to save time.`,
      params: {
        placement: 'top',
        enableScrolling: false,
      },
      next:'',
      nofocus:false,
      removefocus:'.traffic-source',
      removePreFocus:'.fallback-section'
    },
    {
      target: '.fallback-section', 
      header: {
        title: 'Step 6',
      },
      content: `If blocked traffic fails, a Fallback URL can be used. It's recommended but not required. Similarly, if a user has discarded their blocked traffic, a Discarded Traffic Fallback URL can be used. This is also recommended but not mandatory.`,
      params: {
        placement: 'top',
        enableScrolling: true,
      },
      next:'',
      nofocus:false,
      removefocus:'.security-section',
      removePreFocus:'.advance-section'
    },
    {
      target: '.advance-section', 
      header: {
        title: 'Step 7',
      },
      content: `Frequency capping is a feature that allows you to limit the number of times a person can use an account to access your website.`,
      params: {
        placement: 'top',
        enableScrolling: true,
      },
      next:'',
      nofocus:false,
      removefocus:'.fallback-section',
      removePreFocus:'.submit-campaign'
    },
    {
      target: '.submit-campaign', 
      header: {
        title: 'Step 8',
      },
      content: `Click on submit to save campaign.`,
      params: {
        placement: 'right',
        enableScrolling: true,
      },
      next:'',
      nofocus:false,
      removefocus:'.advance-section',
      removePreFocus:'.campaign-list'
    },
    {
      target: '.campaign-list', 
      header: {
        title: 'Step 9',
      },
      content: `Please click here to check Campaign List.`,
      params: {
        placement: 'right',
        enableScrolling: true,
      },
      next:'/campaign/campaignlist',
      nofocus:false,
      removefocus:'.submit-campaign',
      removePreFocus:'.sdk-menu'
    }
   ],
   step3:[
    {
      target: '#campurlforselect', 
      header: {
        title: 'Step 10',
      },
      content: `A Campaign URL is a special link that helps track the success of an advertising campaign. <br>
                The link can be customized by changing certain codes, like "subXX", to track specific information,<br> such as revenue on conversion.`,
      params: {
        placement: 'top',
        enableScrolling: true,
      },
      next:'',
      nofocus:false,
      removefocus:'#campurlforselect',
      removePreFocus:'#campurlforselect',
      id:'campurlModal'
    },
    {
      target: '.sdk-menu', 
      header: {
        title: 'Step 11',
      },
      content: `Click here to generate SDK`,
      params: {
        placement: 'left',
        enableScrolling: true,
      },
      next:'',
      nofocus:false,
      removefocus:'#campurlforselect',
      next:'/campaign/generatesdk',
      removePreFocus:'#campurlforselect'
    }
   ],
   step4:[
    {
      target: '.generatesdk', 
      header: {
        title: 'Step 12',
      },
      content: `If you are planning to use the inspector tool and heatmap, select relevant campaigns and click on Generate Code button. The code that is generated need to be pasted into your landing page. If you need more customization, visit our documentation or contact us.`,
      params: {
        placement: 'top',
        enableScrolling: false
      },
      nofocus:false,
      next:'',
      removePreFocus:'.report-menu'
    },
    {
      target: '.report-menu', 
      header: {
        title: 'Step 13',
      },
      content: `Click here to view Report.`,
      params: {
        placement: 'right',
        enableScrolling: false
      },
      nofocus:false,
      next:'/report',
      removefocus:'.generatesdk',
      removePreFocus:'.report_filter'
    }
   ],
   step5:[
    {
      target: '.report_filter', 
      header: {
        title: 'Step 14',
      },
      content: `Step 1: Choose Date Range - Select the date range for which you wish to view your report, or leave it as "All" to view reports for all available dates.<br>
                Step 2: Select Time Zone - Choose the time zone in which you wish to view your reports. If you need to make a change, use the drop-down menu to select a different option.<br>
                Step 3: View by - View your report on a daily, hourly, monthly, or yearly basis.<br>`,
      params: {
        placement: 'top',
        enableScrolling: false
      },
      nofocus:false,
      removefocus:false,
      next:'',
      removePreFocus:'.group-by'
    },
    {
      target: '.group-by', 
      header: {
        title: 'Step 15',
      },
      content: `To Check The User On The Landing Page, Select "External ID" In The "Group By" Option.`,
      params: {
        placement: 'top',
        enableScrolling: false
      },
      nofocus:false,
      removefocus:'.report_filter',
      next:'',
      removePreFocus:'.report-actions'
    },
    {
      target: '.report-actions', 
      header: {
        title: 'Step 16',
      },
      content: `To Run The Query, Click On The "Run" Button. After The Report Is Generated, Click On "CSV" To Save Or Download The Report As A CSV File. To Reuse The Same Filter In The Future, Click "Save Query"`,
      params: {
        placement: 'top',
        enableScrolling: false
      },
      nofocus:false,
      removefocus:'.group-by',
      next:'',
      removePreFocus:'.external-id-vid'
    },
    {
      target: '.external-id-vid', 
      header: {
        title: 'Step 17',
      },
      content: `Click on External ID to check the user on landing page.`,
      params: {
        placement: 'top',
        enableScrolling: false
      },
      nofocus:false,
      removefocus:'.report-actions',
      next:'',
      islast_tour:true
    }],
    step6:[
    {
      target: '.offerinfo', 
      header: {
        title: 'Step 3.1',
      },
      content: `Please enter all relevant information related to your offering. Once you've finished the steps, click on Next.`,
      params: {
        placement: 'bottom',
        enableScrolling: true,
      },
      next:'',
      nextfocus:'.offer_targetting',
      removefocus:'.offerinfo',
      removePreFocus:'.offer_targetting'
    },
    {
      target: '.offer_targetting', 
      header: {
        title: 'Step 3.2',
      },
      content: `The Offer Targeting option is an optional feature. If it's not a match, the user gets redirected to a discarded URL.
      Discarded URL can be configured from Campaign later.`,
      params: {
        placement: 'top',
        enableScrolling: true
      },
      next:'',
      removefocus:'.offerinfo',
      removePreFocus:'.submit_offer'
    },
    {
      target: '.submit_offer', 
      header: {
        title: 'Step 3.3',
      },
      content: `Click on submit to save Offer.`,
      params: {
        placement: 'top',
        enableScrolling: true
      },
      next:'',
      closeModal:true,
      removefocus:'.offer_targetting',
      removePreFocus:'.submit_offer'
    }
    ],
    step7:[
      {
        target: '.tsinfo', 
        header: {
          title: 'Step 4.1',
        },
        content: `Once you have added a traffic source, it can be reused multiple times.
        This means that you won't have to add the same traffic source again and again, which saves time and effort.
        Example Google`,
        params: {
          placement: 'bottom',
          enableScrolling: true,
        },
        next:'',
        removefocus:false,
        removePreFocus:'.submit_ts'
      },
      {
        target: '.submit_ts', 
        header: {
          title: 'Step 4.2',
        },
        content: `Click on submit to save Traffic Source.`,
        params: {
          placement: 'top',
          enableScrolling: true
        },
        next:'',
        closeModal:true,
        removefocus:'.tsinfo',
        removePreFocus:'.create-campaign'
      },
    ]
}