<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
    :show = "collapse"
    ref="dropdown"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
          width="width: 60%;"
            src="img/avatars/user_avtar.png"
            class="c-avatar-img "
          />
          <!-- <CIcon name="cil-settings" style="height: 25px;width: 25px;" /> -->
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <span @click="gotoPage('PlanInfo')" class="text-info"><CIcon name="cil-user"/> {{usermail}}</span>
      <!-- <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge> -->
      </CDropdownItem>
    <span v-if="user == 'csp'">
      <CDropdownItem v-if="user == 'csp' && isViewLog !='false'" @click="gotoPage('ClickFinder')">
      <CIcon name="cil-search" /> View Logs
      </CDropdownItem>
      <CDropdownItem v-if="user == 'csp' && iwitness != 'false'" @click="gotoPage('Iwitness')">
      <CIcon name="cil-hand-point-right" /> Inspector
      </CDropdownItem>
    </span>
    <span v-if="user != 'manager' && user != 'csp' && user != 'moderator'">
    <CDropdownItem v-if="user!='subUser'" @click="gotoPage('SubUser')">
    <CIcon name="cil-user" /> Sub Users
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('ClickFinder')">
    <CIcon name="cil-search" /> View Logs
    </CDropdownItem>
    <CDropdownItem v-if="user != 'manager' && iwitness != 'false'" @click="gotoPage('Iwitness')">
    <CIcon name="cil-hand-point-right" /> Inspector
    </CDropdownItem>
    <CDropdownItem v-if="user != 'manager' && iwitness != 'false'" @click="gotoPage('HitMap')">
    <CIcon name="cil-hand-point-right" /> HeatMap
    </CDropdownItem>
    <CDropdownItem v-if="user != 'manager'" @click="gotoPage('PostbackLogs')">
    <CIcon name="cil-hand-point-right" /> Postback Logs
    </CDropdownItem>
    <CDropdownItem v-if="user != 'manager' && user!='subUser' && user != 'csp'" @click="gotoPage('ActivityLogs')">
    <CIcon name="cil-hand-point-right" /> Activity Logs
    </CDropdownItem>
    <!-- <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Setting</strong>
    </CDropdownHeader> -->
    <!-- <CButton 
      @click="collapse = !collapse" 
      color="light"
      class="mb-2 text-center col-md-12 settings"
    >
      Settings
    </CButton>
    <CCollapse :show="collapse">
      <CCard body-wrapper>
        <CDropdownItem @click="gotoPage('Account')">
      <CIcon name="cil-settings" /> Account Setting
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('PlanInfo')">
      <CIcon name="cil-settings" /> Plan Info
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('Master')">
      <CIcon name="cil-settings" /> Master Security
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('Javascript')">
      <CIcon name="cil-settings" /> Javascript Setting
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('APK')">
      <CIcon name="cil-settings" /> APK Security
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('Domain')">
      <CIcon name="cil-settings" /> Domain Security
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('IP')">
      <CIcon name="cil-settings" /> IP Whitelist
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('Postback')">
      <CIcon name="cil-settings" /> Postback Security
    </CDropdownItem>
    <CDropdownItem @click="gotoPage('Developers')">
      <CIcon name="cil-settings" /> Developers
    </CDropdownItem>
      </CCard>
    </CCollapse> -->
    </span>
    <span v-if="user == 'manager'">
      <CDropdownItem @click="gotoPage('UserList')">
      <CIcon name="cil-file" /> UserList
      </CDropdownItem>
      <CDropdownItem @click="gotoPage('Plan Creator')">
      <CIcon name="cil-file" /> Plan Creator
      </CDropdownItem>
      <CDropdownItem @click="gotoPage('Plan Allocator')">
      <CIcon name="cil-file" /> Plan Allocator
      </CDropdownItem>
      <CDropdownItem @click="gotoPage('Error Logs')">
      <CIcon name="cil-file" /> Error Logs
      </CDropdownItem>
    </span>
    <CDropdownDivider/>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
    
    <!-- <CDropdownItem>
    <CIcon name="cil-settings"/>Setting
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <!-- <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem> -->
  </CDropdown>
</template>

<script>
import LoginService from '../services/loginControlService'
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      user:'',
      user_status:false,
      usermail:'',
      iwitness:'false',
      isViewLog:'false',
      collapse: false,
    }
  },
  mounted(){
    this.usermail = window.localStorage.getItem("email");
    this.user = window.localStorage.getItem("user");
    this.user_status = window.localStorage.getItem("status");
    this.iwitness = window.localStorage.getItem("iwitness");
    this.isViewLog = window.localStorage.getItem("isViewLog");
    window.addEventListener('keydown', this.onKeyDown);
  },
  methods:{
    async logout() {
      let response = await LoginService.logout(this.input)
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          window.localStorage.setItem("email", null);
          this.$router.push("/pages/login");
          location.reload();
      },
      gotoPage(txt){
        switch(txt){
          case 'Account':
            this.$router.push("/setting/accountsetting");
            break;
          case 'PlanInfo':
            this.$router.push("/setting/planinfo");
            break;
          case 'Master':
            this.$router.push("/setting/mastersetting");
            break;
          case 'Javascript':
            this.$router.push("/setting/jssetting");
            break;
          case 'APK':
            this.$router.push("/setting/apksetting");
            break;
          case 'Domain':
            this.$router.push("/setting/domain");
            break;
          case 'IP':
            this.$router.push("/setting/ip");
            break;
          case 'Postback':
            this.$router.push("/setting/postbacksecurity");
            break;
          case 'Developers':
            this.$router.push("/setting/developer");
            break;
          case 'UserList':
            this.$router.push("/manager/userlist");
            break;
          case 'Plan Creator':
            this.$router.push("/manager/createplan");
            break;
          case 'Plan Allocator':
            this.$router.push("/manager/planallocate");
            break;
          case 'Error Logs':
            this.$router.push("/manager/errorlog");
            break;
          case 'ClickFinder':
            this.$router.push("/campaign/clickfinder");
            break;
          case 'Iwitness':
          this.$router.push("/campaign/iwitness");
          break;
          case 'PostbackLogs':
          this.$router.push("/setting/postbacklogs");
          break;
          case 'ActivityLogs':
          this.$router.push("/setting/activitylogs");
          break;
          case 'HitMap':
          this.$router.push("/campaign/hitmap");
          break;
          case 'SubUser':
          this.$router.push("/subuser");
          break;
        }
        this.collapse = false
      },
      onKeyDown(event) {
      if (event.keyCode === 27) { // escape key
        this.$refs.dropdown.hide();
      }
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
  .settings:hover{
    color: rgb(2, 23, 90)
  }
</style>