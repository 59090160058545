<template>
  <div>
  <CHeader fixed with-subheader light>
    <div class=""
    name="logo" 
    v-if="user == 'manager'"
    >
    <!-- <img
    class="mt-2 ml-2"
      src="img/logo/fraudavoid_ logo.png"
      style="height:40px; width:130px;"
    /> -->
    </div>
    <CToggler 
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <!-- @click="$store.commit('set', ['sidebarMinimize', !minimize])" -->
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
    <div class="c-sidebar-brand-full" v-if="user != 'manager'"
    name="logo" 
    size="custom-size" 
    >
    <img
      src="img/logo/sidelogo.png"
      style="height:40px; width:130px;"
    />
    </div>
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem v-if="user != 'manager' && user != 'moderator'" class="px-3">
        <CHeaderNavLink to="/dashboard">
          <span class="text-muted">Dashboard</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem v-if="user != 'manager' && user != 'csp' && user != 'moderator'" class="px-3">
        <CHeaderNavLink to="/campaign/clickfinder" exact>
          <span class="text-muted">View Logs</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!--start View logs only for CSP and approved for it  -->
      <CHeaderNavItem v-if="user == 'csp' && isViewLog !='false'" class="px-3">
        <CHeaderNavLink to="/campaign/clickfinder" exact>
          <span class="text-muted">View Logs</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- end View logs only for CSP and approved for it  -->
       <!-- start Iwitness only for CSP and approved for it  -->
       <CHeaderNavItem v-if="user == 'csp' && iwitness != 'false'" class="px-3">
        <CHeaderNavLink to="/campaign/iwitness" exact>
          <span class="text-muted">Inspector</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- end Iwitness only for CSP and approved for it  -->
       <CHeaderNavItem v-if="(user != 'manager' && user != 'csp' && user != 'moderator') && iwitness != 'false'" class="px-3">
        <CHeaderNavLink to="/campaign/iwitness" exact>
          <span class="text-muted">Inspector</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem  class="px-3" v-if="user == 'user'">
        <CHeaderNavLink exact>
          <span class="text-muted" @click="enableUserGuide()">Help</span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- <CHeaderNavItem v-if="user == 'manager'" class="px-3">
        <CHeaderNavLink to="/manager/userlist" exact>
          UserList
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem v-if="user == 'manager'" class="px-3">
        <CHeaderNavLink to="/manager/createplan" exact>
          Plan Creator
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem v-if="user == 'manager'" class="px-3">
        <CHeaderNavLink to="/manager/planallocate" exact>
          Plan Allocator
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem v-if="user == 'manager'" class="px-3">
        <CHeaderNavLink to="/manager/errorlog" exact>
          Error Logs
        </CHeaderNavLink>
      </CHeaderNavItem> -->
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink>
          Settings
        </CHeaderNavLink>
      </CHeaderNavItem> -->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2" v-if="user == 'user'">
        <CHeaderNavLink>
          <CButton
            class="plan_info_btn"
            v-if="plan_details.planType == 1"
            color="primary"
            variant="outline"
            shape="pill"
            size="sm"
            @click="update_plan_modal = true">Upgrade to Premium</CButton>
            <CButton
            v-else
            color="primary"
            variant="outline"
            shape="pill"
            size="sm"
            class="plan_info_btn"
            @click="update_plan_modal = true">Plan Info</CButton>
         <!-- <span class="text-info" v-if="plan_details.plan_name == 'free'" @click="update_plan_modal = true">Upgrate to Premium</span>  -->
         <!-- <span class="text-info" v-else @click="update_plan_modal = true">Plan Info</span>  -->
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2" v-if="user != 'manager' && user!= 'moderator'">
        <CHeaderNavLink to="/dashboard">
           <span>Click : <span v-if="plan_details.receive_clicks >plan_details.total_clicks">Limit exceeded</span>
            <span v-else><strong>{{plan_details.receive_clicks}}/{{plan_details.total_clicks}}</strong></span></span>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <!-- <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink> -->
      <!-- </CHeaderNavItem> -->
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <!-- Sub header for mobile view  -->
    <!-- <CSubheader class="px-3" id="mobileshow" v-if="user != 'manager'">
      <div class="mt-2">
        <CRow class="col-12 pb-1">
        <CCol class="col-6">
          <CRow>
            <CImg src="img/logo/icn-1.png" class="img-fluid ml-1" title="Plan" style="cursor: pointer;" ></CImg>
            <span class="ml-1">Plan : <strong>{{plan_details.plan_name}}</strong></span>
          </CRow>
        </CCol>
        <CCol class="col-6">
          <CRow>
            <CImg src="img/logo/icn-3.png" class="img-fluid ml-1" title="Plan" style="cursor: pointer;" ></CImg>
            <span>Click :<span v-if="plan_details.receive_clicks >plan_details.total_clicks">Limit exceeded</span>
            <span v-else><strong>{{plan_details.receive_clicks}}/{{plan_details.total_clicks}}</strong></span></span>
          </CRow>
        </CCol>
        <CCol class="col-6">
          <CRow>
            <CImg src="img/logo/icn-2.png" class="img-fluid ml-1" title="Plan" style="cursor: pointer;" ></CImg>
            <span> Type : <strong>{{plan_details.type}}</strong> </span>
          </CRow>
        </CCol>
        </CRow>
      </div>
    </CSubheader> -->
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
    <!-- User Guide start -->
    <CModal
      title="Flow"
      :show.sync="user_guide"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <center>
    <CImg src="img/introducer.gif" class="img-fluid" style="max-height: 500px;"></CImg>
    </center>
    <template #footer>
      <CRow class="mt-3">
      <CCol class="text-left">
        <div class="next_intro">
          <CButton class="" @click="enableHelp()" color="info">Next Step</CButton>
        </div>
      </CCol>
    </CRow>
      <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
    </template>
    </CModal>
    <!-- User Guide end -->
    <vue-tour :name="'sidebar'"></vue-tour>
  </CHeader>
  <!-- Update plan modal start -->
  <CModal
      title="Plan info"
      :show.sync="update_plan_modal"
      :centered="true"
      scrollable
      color="dark"
      size="xl"
    >
    <upgrade-plan v-if="user == 'user'" :update_plan_modal="update_plan_modal"></upgrade-plan>
    <template #footer>
      <CButton @click="update_plan_modal = false" color="danger" style="display: none;">Close</CButton>
    </template>

    </CModal>
    <!-- Update plan modal end --></div>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import DashboardService from "./../services/dashboardController";
import VueTour from "../components/vueTour.vue";
import UpgradePlan from '../components/UpgradePlan.vue';
export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,VueTour,UpgradePlan
  },
  data(){
    return{
      update_plan_modal:false,
      user_guide:false,
      steps:[],
      user:'',
      user_status:false,
      usermail:'',
      plan_details:{plan_name:'Plan not Activated',type:"",total_clicks:0,receive_clicks:0},
      iwitness:'false',
      isViewLog:'false'
    }
  },
 async mounted(){
    this.usermail = window.localStorage.getItem("email");
    this.user = window.localStorage.getItem("user");
    this.user_status = window.localStorage.getItem("status");
    this.iwitness = window.localStorage.getItem("iwitness");
    this.isViewLog = window.localStorage.getItem("isViewLog");
    if(this.user_status == 'true' && this.user == 'user'){
      this.getPlanDetails()
    }
  },
  methods:{
    async getPlanDetails(){
      let response = await DashboardService.getplandetails();
      if(response.result){
      this.plan_details = response.message
      }
    },
    enableUserGuide(){
      var classes = ['.create-offer','.traffic-source','.create-campaign','.sdk-menu','.report-menu','.campaign-list']
        classes.map((t)=> {
          var ele = document.querySelector(t);
          if(ele){ele.classList.remove('focus_guide');}
        })
        this.$tours['sidebar'].stop()
      if(window.location.hash !='#/dashboard'){
          this.$router.push("/dashboard");
      }
      this.user_guide = true
    },
    enableHelp(){
      this.user_guide = false
      window.localStorage.setItem('guide_tool', false)
      window.localStorage.setItem('guide_tool', true)
      var element = document.querySelector('.create-campaign');
      element.className += " " + 'focus_guide';
      this.$tours['sidebar'].start()
    }
  }
}
</script>
<style scoped>
.plan_info{
  background-color:#5d6679ed;color:white;border-radius: 10px;
}
#mobileshow { 
display:none; 
}
@media screen and (max-width: 768px ) {
#mobileshow { 
display:block; }
}
</style>

<style>
.modal-upgrade{
  max-width: 70% !important;
}
</style>